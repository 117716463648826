<template>
  <section id="home" class="opacity-0 fade-in cursor-default">
    <div
      class="w-100 h-screen bg-cover overflow-hidden relative"
      style="background-image: url('https://i.imgur.com/xMIdtD7.jpeg')"
    >
      <div
        class="w-full h-screen absolute top-0 opacity-60"
        style="background-image: url('/img/rebel.png')"
      ></div>
      <div
        class="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black h-full flex items-end justify-center"
      >
        
      </div>

      <div class="absolute top-0 z-50 w-full h-full flex items-center" style="pointer-events: none;">
        <div class="mx-auto p-4 text-white slide-up opacity-0">
          <div class="md:flex block">
            <div>
              <img
                src="https://i.imgur.com/7oePKHS.png"
                class="shadow-2xl max-h-32 rounded-full md:ml-auto md:mr-5 mx-auto mb-3 md:mb-0"
              />
            </div>

            <div class="" style="pointer-events: auto;">
              <h1 class="font-ubuntu font-bold text-5xl">
                Hey, I'm <span class="text-orange-500">Ty</span>!
              </h1>
              <h2 class="font-ubuntu text-2xl mt-1">
                Software Developer
              </h2>

              <div class="w-full text-left mt-2">
                <div class="flex space-x-4 items-center">
                  <a href="mailto:hello@tysonpm.ca" target="_blank">
                    <i
                      class="fa-solid fa-envelope text-white text-2xl hover:text-[#f97316] transition"
                    ></i>
                  </a>

                  <a href="https://github.com/NotTyson" target="_blank">
                    <i
                      class="fa-brands fa-github text-white text-2xl hover:text-[#f97316] transition"
                    ></i>
                  </a>

                  <a href="https://instagram.com/TysonPM" target="_blank">
                    <i
                      class="fa-brands fa-instagram text-white text-2xl hover:text-[#f97316] transition"
                    ></i>
                  </a>

                  <a href="https://www.linkedin.com/in/tysonpm" target="_blank">
                    <i
                      class="fa-brands fa-linkedin text-white text-2xl hover:text-[#f97316] transition"
                    ></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
    methods: {
        scrollToSection(sectionId) {
            const section = document.getElementById(sectionId);

            if (section) {
                window.scrollTo({
                    top: section.offsetTop,
                    behavior: 'smooth',
                });
            }
        },
    }
};
</script>

<style scoped>
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 2s ease-out .5s forwards;
  -webkit-animation: fadeIn 2s ease-out .5s forwards;
}

@keyframes slideUp {
  from {
    transform: translateY(80px);
    opacity: 0;
  }

  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

.slide-up {
  animation: slideUp 1.5s ease-in-out 2s forwards;
  -webkit-animation: slideUp 1.5s ease-in-out 2s forwards;
}
</style>
