<template>
  <section id="about">
    <div class="container mx-auto text-white text-center lg:py-56 py-24 font-ubuntu">
      <div class="max-w-2xl mx-auto px-12">
        <h1 class="text-4xl font-semibold mb-3 opacity-0" id="title">
          About Me
        </h1>
        <p id="top-text" class="opacity-0 text-left">
          I am a seasoned full-stack developer based in Canada. I began developing back in 2010, originally
          creating Minecraft server plugins. Since then, my expertise has expanded across a wide range, including
          network systems, software security, blockchain & crypto, cloud service management, information security, front
          end development, backend development, and more.
        </p><br />
        <p id="mid-text" class="opacity-0 text-left">
          In the course of my professional journey, I've translated several of my passion projects into successful small
          businesses. Notably, I managed a website and server hosting service catering to independent developers, and I
          co-founded a digital rights management service, amassing over 17,000 end users.
        </p><br />
        <p id="bot-text" class="opacity-0 text-left">
          One of my core principles is a commitment to finding the most cost-effective solutions for my clients.
          Recognizing the importance of efficiency and budget-conscious strategies, I leverage my technical expertise to
          optimize projects without compromising quality.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  mounted() {
    this.intersectionObv();
  },
  methods: {
    intersectionObv() {
      let title = document.getElementById("title");
      let toptext = document.getElementById("top-text");
      let midtext = document.getElementById("mid-text");
      let bottext = document.getElementById("bot-text");

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("slide-up");
          } else {
            entry.target.classList.remove("slide-up");
          }
        });
      });

      // Observe the elements
      observer.observe(title);
      observer.observe(toptext);
      observer.observe(midtext);
      observer.observe(bottext);
    },
  },
};
</script>

<style scoped>
@keyframes fadeSlide {
  from {
    transform: translateY(200px);
    opacity: 0;
  }

  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

.slide-up {
  animation: fadeSlide .5s ease-out .25s forwards;
  -webkit-animation: fadeSlide .5s ease-out .25s forwards;
}
</style>
