<template>
    <section id="current">
        <div class="bg-gray-300 w-full cursor-default">
            <div class="py-12 px-6">
                <div id="ember" class="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div class="mx-auto my-auto">
                        <div class="flex items-end">
                            <img src="/img/ember-logo.png" width="40px" />
                            <p class="font-open-sans font-light text-3xl ml-2">Ember Software</p>
                        </div>
                    </div>

                    <div class="mx-auto my-auto">
                        <div class="text-center">
                            <h2 class="text-xl font-roboto font-light">Igniting Possibilities, Building Excellence
                            </h2>
                            <p>Software & Website Development</p>
                        </div>
                    </div>

                    <div class="mx-auto my-auto">
                        <a href="https://embersoftware.ca" target="_blank">
                            <button class="bg-[#f97316] hover:bg-[#e36812] rounded-lg p-4 text-white">
                                Visit Ember Software
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    mounted() {
        this.$nextTick(() => {
            this.intersectionObv();
        });
    },
    methods: {
        intersectionObv() {
            let emberText = document.getElementById("ember");

            const observer = new IntersectionObserver((enteries) => {
                enteries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        console.log(entry);
                        entry.target.classList.add("fade-in");
                    } else {
                        entry.target.classList.remove("fade-in");
                    }
                });
            });

            observer.observe(emberText);
        }
    }
};
</script>

<style scoped>
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fade-in {
    animation: fadeIn 1s ease-in forwards;
    -webkit-animation: fadeIn 1s ease-in forwards;
}
</style>
