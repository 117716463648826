<template>
  <section id="experience" class="cursor-default bg-carto">
    <div class="grid grid-cols-1 xl:grid-cols-4 gap-4 min-w-screen font-ubuntu pt-5 bg-gradient">
      <div class="px-12 xl:py-12 hidden xl:block">
        <div class="sticky top-16 lg:px-4 opacity-0" id="sidename">
          <h1 class="text-lg lg:text-2xl font-semibold text-[#f97316]">
            Tyson Prefontaine-McRae
          </h1>
          <h2 class="text-base font-semibold text-[#e2e8f0] mb-4">
            Software Developer
          </h2>
          <p class="text-[#c1c5ca] text-base">
            I help small businesses and people make the most of their tech.
          </p>

          <div class="w-full text-left">
            <div class="flex space-x-4 items-center">
              <a href="mailto:hello@tysonpm.ca" target="_blank">
                <i class="fa-solid fa-envelope text-white text-2xl hover:text-[#f97316] transition"></i>
              </a>

              <a href="https://github.com/NotTyson" target="_blank">
                <i class="fa-brands fa-github text-white text-2xl hover:text-[#f97316] transition"></i>
              </a>

              <a href="https://instagram.com/TysonPM" target="_blank">
                <i class="fa-brands fa-instagram text-white text-2xl hover:text-[#f97316] transition"></i>
              </a>

              <a href="https://www.linkedin.com/in/tysonpm" target="_blank">
                <i class="fa-brands fa-linkedin text-white text-2xl hover:text-[#f97316] transition"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- ... -->
      <div class="px-12 xl:py-12 pb-12 col-span-3 overflow-hidden">

        <div id="education" class="opacity-0">
          <div class="text-[#e2e8f0] text-3xl font-semibold lg:px-4 pb-1">
            <p>Education</p>
          </div>

          <div class="hover:bg-[#101010] bg-[#101010] lg:bg-black transition rounded-lg lg:px-4 px-4 py-3 my-2">
            <div class="grid lg: lg:grid-cols-5 grid-cols-1">
              <div class="text-[#6c7071] uppercase font-light pt-1">
                SEP 2018 ─ MAY 2020
              </div>
              <div class="col-span-4">
                <p class="font-semibold text-[#f97316] text-xl">
                  DCS in Information Technology
                </p>
                <p class="text-gray-200 text-lg">Network Systems</p>
                <p class="text-gray-200 text-base">
                  Southern Alberta Institute of Technology
                </p>
              </div>
            </div>
          </div>
        </div>

        <div id="suns" class="opacity-0">
          <div class="text-[#e2e8f0] text-3xl font-semibold lg:px-4 py-1">
            <p>Experience</p>
          </div>

          <div class="hover:bg-[#101010] bg-[#101010] lg:bg-black transition rounded-lg lg:px-4 px-4 py-3 my-2">
            <div class="grid lg: lg:grid-cols-5 grid-cols-1">
              <div class="text-[#6c7071] uppercase font-light pt-1">
                JUN 2020 ─ PRESENT
              </div>
              <div class="col-span-4">
                <p class="text-gray-100 text-xl mb-1">
                  <span class="font-semibold text-[#f97316]">Software Developer</span> · 2Suns Software
                </p>
                <p class="text-[#c1c5ca]">
                  - Co-founded 2Suns, an independent software development firm to
                  bring innovative solutions to small businesses
                </p>
                <p class="text-[#c1c5ca]">
                  - Worked closely with clients to understand their unique needs
                  and developed customized software solutions that exceeded their
                  expectations
                </p>
                <p class="text-[#c1c5ca]">
                  - Demonstrated strong project management skills, ensuring
                  projects were completed on-time and within budget
                </p>
                <p class="text-[#c1c5ca]">
                  - Maintained strong client relationships, communicating
                  effectively to manage expectations and ensure client
                  satisfaction
                </p>
              </div>
            </div>
          </div>
        </div>

        <div id="thepeak" class="opacity-0">
          <div class="hover:bg-[#101010] bg-[#101010] lg:bg-black transition rounded-lg lg:px-4 px-4 py-3 my-2">
            <div class="grid lg:grid-cols-5 grid-cols-1">
              <div class="text-[#6c7071] uppercase font-light pt-1">
                NOV 2021 ─ JUL 2022
              </div>
              <div class="col-span-4">
                <p class="text-gray-100 text-xl mb-1">
                  <span class="font-semibold text-[#f97316]">Website Developer</span> · The Peak Cannabis Co.
                </p>
                <p class="text-[#c1c5ca]">
                  - Redesigned their website to look more modern and favour faster
                  load times.
                </p>
                <p class="text-[#c1c5ca]">
                  - Built a customer management system for securely, safely and
                  easily verifying customer identities as well as processing
                  online orders.
                </p>
                <p class="text-[#c1c5ca]">
                  - Ensured compliance with all provincial and federal regulations
                  regarding cannabis advertising, sales, and deliveries.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div id="vanguard" class="opacity-0">
          <div class="hover:bg-[#101010] bg-[#101010] lg:bg-black transition rounded-lg lg:px-4 px-4 py-3 my-2">
            <div class="grid lg:grid-cols-5 grid-cols-1">
              <div class="text-[#6c7071] uppercase font-light pt-1">
                MAR 2018 ─ FEB 2021
              </div>
              <div class="col-span-4">
                <p class="text-gray-100 text-xl mb-1">
                  <span class="font-semibold text-[#f97316]">Developer</span> · Vanguard Loaders
                </p>
                <p class="text-[#c1c5ca]">
                  - Co-founded Vanguard Loaders, a digital rights management
                  company offering solutions to protect software from piracy,
                  reverse engineering and tampering
                </p>
                <p class="text-[#c1c5ca]">
                  - Managed the server network to ensure stable and secure
                  operation of the product for over 17,000 end users
                </p>
                <p class="text-[#c1c5ca]">
                  - Designed and built the company website to increase online
                  presence and attract new clients
                </p>
                <p class="text-[#c1c5ca]">
                  - Conducted security audits on the product to identify
                  vulnerabilities and implement necessary fixes
                </p>
                <p class="text-[#c1c5ca]">
                  - Provided exceptional customer support to clients, resolving
                  inquiries and concerns in a timely and effective manner
                </p>
              </div>
            </div>
          </div>
        </div>

        <div id="ember" class="opacity-0">
          <div class="hover:bg-[#101010] bg-[#101010] lg:bg-black transition rounded-lg lg:px-4 px-4 py-3 my-2">
            <div class="grid lg:grid-cols-5 grid-cols-1">
              <div class="text-[#6c7071] uppercase font-light pt-1">
                DEC 2016 ─ JAN 2019
              </div>
              <div class="col-span-4">
                <p class="text-gray-100 text-xl mb-1">
                  <span class="font-semibold text-[#f97316]">Team Lead</span> · Ember Servers
                </p>
                <p class="text-[#c1c5ca]">
                  - Founded Ember Servers, website and server hosting company
                  catering to independent developers
                </p>
                <p class="text-[#c1c5ca]">
                  - Designed and built the company website to increase online
                  presence and attract new clients
                </p>
                <p class="text-[#c1c5ca]">
                  - Directed a three-person customer support team, providing
                  exceptional service and resolving customer inquiries and
                  concerns in a timely manner over support tickets
                </p>
                <p class="text-[#c1c5ca]">
                  - Oversaw all daily operations of the company, ensuring smooth
                  functioning of all systems and processes
                </p>
                <p class="text-[#c1c5ca]">
                  - Utilized and heavily modified WHMCS to streamline company
                  operations and improve efficiency
                </p>
                <p class="text-[#c1c5ca]">
                  - Implemented new customer service procedures that improved
                  customer satisfaction ratings
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      scrollOffset: 100, // Adjust the scroll offset as needed
    };
  },
  mounted() {
    this.intersectionObv();
  },
  methods: {
    intersectionObv() {
      let sidename = document.getElementById("sidename");
      let education = document.getElementById("education");
      let suns = document.getElementById("suns");
      let vanguard = document.getElementById("vanguard");
      let thepeak = document.getElementById("thepeak");
      let ember = document.getElementById("ember");

      const elements = [education, suns, vanguard, thepeak, ember];

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (entry.target.id === "education") {
              sidename.classList.add("slide-left");
            }
            entry.target.classList.add("slide-right");
          } else {
            entry.target.classList.remove("slide-right");
          }
        });
      },
        {
          rootMargin: "0px 0px -50px 0px", // Add 100px to the bottom
        });

      // Observe the elements
      elements.forEach((element) => {
        observer.observe(element);
      });
    },
  },
};
</script>

<style scoped>
@keyframes slideRight {
  from {
    transform: translateX(15rem);
    opacity: 0;
  }

  to {
    transform: translateX(0%);
    opacity: 1;
  }
}

.slide-right {
  animation: slideRight .5s ease-out forwards;
}

@keyframes slideLeft {
  from {
    transform: translateY(15rem);
    opacity: 0;
  }

  to {
    transform: translateX(0%);
    opacity: 1;
  }
}

.slide-left {
  animation: slideLeft .5s ease-out forwards;
}

.bg-carto {
  background-image: url('https://i.imgur.com/MKsWgB1.png');
}

.bg-gradient {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}
</style>