<template>
  <NavBar />
  <LandingHeader />
  <LandingAbout />
  <LandingResume />
  <LandingEmber />
  <LandingSkills />
  <LandingContact />
  <LandingFooter />
  <VuePreloader background-color="#000" color="#f97316" transition-type="fade-up" :loading-speed="15"
    :transition-speed="1" @loading-is-over="loadingIsOver" @transition-is-over="transitionIsOver"></VuePreloader>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import LandingHeader from "./components/LandingHeader.vue";
import LandingAbout from "./components/LandingAbout.vue";
import LandingResume from "./components/LandingResume.vue";
import LandingEmber from "./components/LandingEmber.vue";
import LandingSkills from "./components/LandingSkills.vue";
import LandingContact from "./components/LandingContact.vue";
import LandingFooter from "./components/LandingFooter.vue";

import { VuePreloader } from 'vue-preloader';
import '../node_modules/vue-preloader/dist/style.css'

export default {
  name: "App",
  components: {
    NavBar,
    LandingHeader,
    LandingAbout,
    LandingResume,
    LandingEmber,
    LandingSkills,
    LandingContact,
    LandingFooter,
    VuePreloader,
  },
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: black;
}

._percentBar_1auhy_29 {
    display: none !important;
}
</style>
