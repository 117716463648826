<template>
    <section id="footer">
        <div class="bg-[#black] w-full p-12 flex justify-center font-ubuntu">
            <div class="mx-auto grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-8 container">
                <div class="mx-auto">
                    <span class="text-[#505050] font-semibold text-lg cursor-default">
                        <i class="fa-solid fa-fire"></i> Tyson PM
                    </span>
                </div>

                <div class="mx-auto text-[#505050]  text-center">
                    <p class="text-lg font-semibold cursor-default">Contact</p>
                    <ul>
                        <li>
                            <a href="mailto:hello@tysonpm.ca" class="hover:text-[#f97316] transition">
                                hello@TysonPM.ca</a>
                        </li>
                    </ul>
                </div>

                <div class="mx-auto text-[#505050]  text-center">
                    <p class="text-lg font-semibold cursor-default">Projects</p>
                    <ul>
                        <li>
                            <a href="https://embersoftware.ca" target="_blank" class="hover:text-[#f97316] transition">Ember Software</a>
                        </li>
                    </ul>
                </div>

                <div class="mx-auto text-[#505050] text-center">
                    <p class="text-lg font-semibold cursor-default">Socials</p>
                    <div class="flex space-x-4 items-center">
                            <a href="https://github.com/NotTyson" target="_blank">
                                <i class="fa-brands fa-github text-[#505050] text-2xl hover:text-[#f97316] transition"></i>
                            </a>

                            <a href="https://instagram.com/TysonPM" target="_blank">
                                <i class="fa-brands fa-instagram text-[#505050] text-2xl hover:text-[#f97316] transition"></i>
                            </a>

                            <a href="https://www.linkedin.com/in/tysonpm" target="_blank">
                                <i class="fa-brands fa-linkedin text-[#505050] text-2xl hover:text-[#f97316] transition"></i>
                            </a>
                        </div>
                </div>
            </div>
        </div>
        <div class="bg-[#black] grid grid-cols-1 pb-5 text-[#303030] cursor-default font-ubuntu">
            <div class="mx-auto">
                <p>&copy; 2020-{{ year }} Tyson Prefontaine-McRae</p>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            year: new Date().getFullYear(),
        };
    },
};
</script>

<style>
.grid-cols-4>div {
    min-height: 0;
}
</style>