<template>
    <div class="fixed top-0 w-full z-50 font-ubuntu">
        <div class="max-w-[48rem] slide-down opacity-0 mx-auto mt-3 px-5 py-2 rounded-full flex bg-[#202020] bg-opacity-50 backdrop-filter backdrop-blur-lg">
            <div>
                <span class="text-gray-50 font-semibold text-lg cursor-pointer hover:text-orange-500 transition hidden sm:block"><i
                        class="fa-solid fa-fire text-orange-500"></i> Ty</span>
            </div>

            <div class="mx-auto sm:ml-auto sm:mx-0">
                <ul class="flex gap-3 nav-list">
                    <li v-for="(section, index) in sections" :key="index" :class="{ 'active': index === activeSection }" class="cursor-pointer">
                        <a @click="scrollToSection(index)">{{ section }}</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            sections: ['Home', 'About', 'Experience', 'Skills', 'Contact'],
            activeSection: 0,
        };
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            const scrollPosition = window.scrollY;

            // Calculate the height of each section
            const sectionHeights = this.sections.map(section => {
                const element = document.getElementById(section.toLowerCase());
                return element ? element.clientHeight : 0;
            });

            // Calculate the -25% mark for triggering the active section change
            const triggerOffset = -0.25 * window.innerHeight;

            let cumulativeHeight = 0;
            for (let i = 0; i < sectionHeights.length; i++) {
                cumulativeHeight += sectionHeights[i];

                if (scrollPosition < cumulativeHeight + triggerOffset) {
                    this.activeSection = i;
                    break;
                }
            }
        },
        scrollToSection(index) {
            const element = document.getElementById(this.sections[index].toLowerCase());
            if (element) {
                window.scrollTo({
                    top: element.offsetTop - 50, // Adjust as needed
                    behavior: 'smooth',
                });
            }
        },
    },
};
</script>

<style scoped>
.nav-list {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0;
}

.nav-list a {
  text-decoration: none;
  color: white;
  transition: color 0.3s;
}

.nav-list li.active a {
  color: #f97316;
}

@keyframes slideDown {
    from {
        transform: translateY(-5rem);
        opacity: 0;
    }
    to {
        transform: translateY(0rem);
        opacity: 1;
    }
}

.slide-down {
    animation: slideDown 2s ease-out 3s forwards;
}
</style>