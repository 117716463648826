<template>
  <div
    class="opacity-0 min-h-[6rem] min-w-[6rem] bg-[#111111] hover:bg-[#f97316] group rounded-lg flex justify-center items-center hover:cursor-default transition-all"
  >
    <img v-if="props.img" :src="props.img" style="height: 2rem;" />
    <i v-if="props.icon" :class="iconClass" class=""></i>
    <svg
      v-if="props.path"
      stroke-width="0"
      role="img"
      viewBox="0 0 24 24"
      height="2rem"
      width="2rem"
      xmlns="http://www.w3.org/2000/svg"
      class="svg-icon fill-white"
    >
      <title></title>
      <path :d="props.path ? props.path : ''"></path>
    </svg>
    <p
      class="text-center absolute bottom-0 text-[12px] font-light font-ubuntu leading-3 font-bold opacity-0 transition group-hover:opacity-100 group-hover:translate-y-[-.3rem]"
    >
      {{ props.text }}
    </p>
  </div>
</template>

<script setup>
import { ref, defineProps } from "vue";

const props = defineProps(["icon", "text", "path", "img", "order"]);

const iconClass = ref("");

if (props.icon) {
  iconClass.value = `${props.icon} text-[2rem]`;
}
</script>
