<template>
  <section id="skills">
    <div class="py-24 bg-[#090909] font-ubuntu">
      <div class="container mx-auto text-white text-center">
        <h2 class="text-3xl font-semibold mb-5">Skills</h2>
      </div>
      <div class="container mx-auto text-white gap-4 flex flex-wrap justify-center" id="skill-cards"
        ref="skillCardsContainer">
        <LandingSkillCard v-for="(skill, index) in skills" :key="index" :style="{ 'animation-delay': index * 0.1 + 's' }"
          :order="index" :icon="skill.icon" :img="skill.img" :text="skill.text" :path="skill.path"
          :class="{ 'bounce-in': isSkillCardsVisible }" />
      </div>
    </div>
  </section>
</template>

<script setup>
import LandingSkillCard from "./LandingSkillCard.vue";
import { ref, onMounted } from "vue";

const skills = [
  { icon: undefined, text: "C# and C++", path: undefined, img: "https://i.imgur.com/GQBFCqD.png" },
  { icon: "fa-brands fa-html5", text: "HTML 5", path: undefined, img: undefined },
  { icon: "fa-brands fa-css3-alt", text: "CSS", path: undefined, img: undefined },
  { text: "Tailwind", order: 4, path: "M12.001,4.8c-3.2,0-5.2,1.6-6,4.8c1.2-1.6,2.6-2.2,4.2-1.8c0.913,0.228,1.565,0.89,2.288,1.624C13.666,10.618,15.027,12,18.001,12c3.2,0,5.2-1.6,6-4.8c-1.2,1.6-2.6,2.2-4.2,1.8c-0.913-0.228-1.565-0.89-2.288-1.624C16.337,6.182,14.976,4.8,12.001,4.8z M6.001,12c-3.2,0-5.2,1.6-6,4.8c1.2-1.6,2.6-2.2,4.2-1.8c0.913,0.228,1.565,0.89,2.288,1.624c1.177,1.194,2.538,2.576,5.512,2.576c3.2,0,5.2-1.6,6-4.8c-1.2,1.6-2.6,2.2-4.2,1.8c-0.913-0.228-1.565-0.89-2.288-1.624C10.337,13.382,8.976,12,6.001,12z" },
  { icon: "fa-brands fa-bootstrap", text: "Bootstrap 5", path: undefined, img: undefined },
  { icon: "fa-brands fa-js", text: "JavaScript", path: undefined, img: undefined },
  { icon: "fa-brands fa-vuejs", text: "Vue3", path: undefined, img: undefined },
  { icon: "fa-brands fa-php", text: "PHP", path: undefined, img: undefined },
];

const isSkillCardsVisible = ref(false);
const skillCardsContainer = ref(null);

onMounted(() => {
  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.5, // Adjust the threshold as needed
  };

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      isSkillCardsVisible.value = entry.isIntersecting;
    });
  }, options);

  if (skillCardsContainer.value) {
    observer.observe(skillCardsContainer.value);
  }
});
</script>

<style scoped>
@keyframes bounceIn {
  0% {
    transform: translateY(8rem);
    opacity: 0;
  }

  70% {
    opacity: 1;
    transform: translateY(-1.5rem);
  }

  80% {
    opacity: 1;
    transform: translateY(-1.5rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0rem);
  }
}

.bounce-in {
  animation: bounceIn .75s ease forwards;
}
</style>