<template>
  <section id="contact">
    <!-- Desktop view -->
    <div class="grid lg:grid-cols-2 grid-cols-1 mx-center text-left font-ubuntu">
      <div class="bg-cover bg-center flex items-center justify-center hidden lg:flex" :style="{
        'background-image': 'url(https://i.imgur.com/xMIdtD7.jpeg)',
      }">
        <div class="w-full h-full top-0 bg-white flex items-center justify-center" :style="{
          'background-image': 'url(img/rebel.png)',
        }" style="background: radial-gradient(circle, rgba(9,9,9,.25) -200%, rgba(9,9,9,1) 100%);">
          <div class="text-center p-5">
            <h2 class="text-white text-3xl font-semibold">Contact me</h2>
            <p class="text-white">
              Send me a message and I'll get back to you as soon as possible!
            </p>
          </div>
        </div>
      </div>

      <div class="bg-[#090909] px-12 flex justify-center items-center lg:min-h-[50vh] text-white">
        <form @submit.prevent="submitForm" class="min-w-[80vw] lg:min-w-0 mb-12">
          <h1 class="text-center text-3xl font-semibold mb-3 block lg:hidden">Contact Me</h1>
          <div class="grid grid-cols-3 gap-3 w-full">
            <div class="mb-4 col-span-3 lg:col-span-1">
              <input type="text" id="name" name="name" v-model="formData.name"
                class="mt-1 p-2 w-full transition duration-500 border bg-[#090909] border-x-0 border-t-0 focus:outline-none focus:border-[#f97316]"
                placeholder="Name" required />
            </div>

            <div class="mb-4 col-span-3 lg:col-span-1">
              <input type="text" id="email" name="email" v-model="formData.email"
                class="mt-1 p-2 w-full transition duration-500 border bg-[#090909] border-x-0 border-t-0 focus:outline-none focus:border-[#f97316]"
                placeholder="Email" required />
            </div>

            <div class="mb-4 col-span-3 lg:col-span-1">
              <input type="tel" id="phone" name="phone" v-model="formData.phone"
                class="mt-1 p-2 w-full transition duration-500 border bg-[#090909] border-x-0 border-t-0 focus:outline-none focus:border-[#f97316]"
                placeholder="Phone (Optional)" />
            </div>
          </div>

          <div class="col-span-3 mb-4">
            <textarea id="message" name="message" v-model="formData.message" rows="8" placeholder="Your Message"
              style="resize: none;"
              class="mt-1 p-2 w-full transition duration-500 bg-[#090909] focus:outline-none border border-x-0 border-t-0 focus:border-[#f97316]"
              required></textarea>
          </div>

          <input type="hidden" name="redirect" value="https://www.tysonpm.ca/success" />

          <button type="submit"
            class="btn col-span-3 w-full border-2 hover:border-[#f97316] hover:text-[#f97316] py-2 rounded transition duration-300">
            Send Message
          </button>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      formData: {
        name: "",
        email: "",
        phone: "",
        message: "",
        access_key: "1ecdc350-659b-4f5d-8284-635a173f5fb0",
      },
    };
  },
  methods: {
    async submitForm() {
      try {
        const response = await fetch("https://api.web3forms.com/submit", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(this.formData),
        });

        if (response.ok) {
          console.log("Form submitted successfully:", response);

          alert("Thank you for your message! I will reach out to you as soon as possible!");

          this.formData.name = "";
          this.formData.email = "";
          this.formData.phone = "";
          this.formData.message = "";
        } else {
          console.error("Form submission failed:", response);
          alert("An unknown error occurred. Please contact me at hello@TysonPM.ca");
        }
      } catch (error) {
        console.error("An error occurred during form submission:", error);
        alert("Sending failed" + "\n" + error + '\nPlease contact me at hello@TysonPM.ca');
      }
    },
  },
};
</script>
